import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Button, Alert} from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import IntlMessages from 'helpers/IntlMessages';
import useCustomLayout from 'hooks/useCustomLayout';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import ProductSearchInput from 'components/product-search-input/ProductSearchInput';
import CustomerSearchInput from 'components/customer-search-input/CustomerSearchInput';
import CouponSearchInput from 'components/coupon-search-input/CouponSearchInput';
import Select from "react-select";
import CurrencyFormat from 'react-currency-format';
import getApiClient from 'api/client';
import Swal from 'sweetalert2';
import moment from 'moment';
import { discountProductID, paymentMethodForSelect, thumbsUrl, noImageFile, outOfStock, channel, defaultOrderType, defaultPaymentGateway } from 'constants/defaultValues';
import { showSpinner, getProductPrice, getCurrentLocation, filterProductItemsBySize, isProductOnDiscount } from 'helpers/Utils';
import { orderVO } from 'constants/valueObjects';
import PaymentModalForm from './PaymentModal';
import PasswordModal from './PasswordModal';
import SalesTrafficLight from 'components/sales-traffic-light/SalesTrafficLight';
import { sendPdfToPrint } from 'helpers/PrintEPSON';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';

const SellModule = ({intl}) => {
  const [barcode, setBarcode] = useState('');
  const [customerUser, setCustomerUser] = useState('');
  const [coupon, setCoupon] = useState('');
  const [productsWithOffer, setProductsWithOffer] = useState(false);
  const [listOfProductsToBuy, setListOfProductsToBuy] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [selectedSeller, setSelectedSeller] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [selectSellerValid, setSelectSellerValid] = useState(false);
  const [selectPaymentMethodValid, setSelectPaymentMethodValid] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [generateChangeTicket, setGenerateChangeTicket] = useState(false);
  const [store, setStore] = useState(null);
  const [sellers, setSellers] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [autoPrint, setAutoPrint] = useState(false);
  const { messages } = intl;

  const title = <IntlMessages id="menu.module.sell.title" />;

  const order = orderVO;

  useEffect(() => {
    const authStatus = localStorage.getItem('isAuthenticated');
    setIsAuthenticated(authStatus === 'true');
  }, []);

  // Get Store data & set Sellers
  useEffect(() => {
    showSpinner(true);
    getApiClient().then(apiClient => {
      apiClient.get('/v1/stores/lovleis.com')
      .then((res) => {return res.data})      
      .then((data) => {
        setStore(data);
        data.sellers
          .filter((seller) => seller.location == getCurrentLocation().id && seller.enabled)
          .map((seller) => {
            var sellerForSelect = { "value": seller.email, "label": seller.name};
            if(sellers.length == 0){
              setSellers(current => [...current, sellerForSelect]);
            }
          }
        );
        showSpinner(false);
      })
      .catch(err => {
        showSpinner(false);
      })
    }); 
  }, []);
  
  const handleProductSearch = (barcode) => {

    const productID = barcode.split('-')[0];
    const size = barcode.split('-')[1];

    showSpinner(true);
    getApiClient()
        .then(apiClient => {
        apiClient
            .get('/v1/products/' + productID)
            .then((res) => res.data)
            .then((data) => {
              showSpinner(false);
              addProductToListToBuy(data, size);
            })
            .catch(err => {
            showSpinner(false);
            if (err.response && err.response.status === 404) {
                Swal.fire({
                title: intl.formatMessage({id: 'module.sell.productToBuy.notfound.title'}),
                text: intl.formatMessage({id: 'module.sell.productToBuy.notfound.text'}, {productID: productID}),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: intl.formatMessage({ id: 'button.accept' }),
                });
            } else {
                console.log(err);
            }
            });
        });        
  };

  function addProductToListToBuy(product, size){

    setCoupon(false);

    const result = filterProductItemsBySize(product.items, size);

    if(result.length){

      product.items = [];
      product.items.push(result[0]);
      setTotalAmount(totalAmount+getProductPrice(product));
      setListOfProductsToBuy(current => [...current, product]);

      if (isProductOnDiscount(product)) {
          setProductsWithOffer(true);
      }
    }else{
      // Revisa si el producto está disponible en otras ubicaciones
      const availableInOtherLocations = product.items.some((item) => 
        item.attributes.find(({ type }) => type === "sizeNumber").value === size
        && item.stockStatus !== outOfStock
      );

      if (availableInOtherLocations) {
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.productToBuy.notAvailableInThisLocation.title'}),
        text: intl.formatMessage({id: 'module.sell.productToBuy.notAvailableInThisLocation.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });
      } else {
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.productToBuy.sizeNotAvailable.title'}),
        text: intl.formatMessage({id: 'module.sell.productToBuy.sizeNotAvailable.text'}, {size: size}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });
      }
    }
  }

  const handleCustomerSearch = (qrCustomerCode) => {

    const customerEmail = qrCustomerCode;

    showSpinner(true);
    getApiClient()
        .then(apiClient => {
        apiClient
            .get('/v1/users/customers/' + customerEmail)
            .then((res) => res.data)
            .then((data) => {
              showSpinner(false);
              setCustomerUser(data)
            })
            .catch(err => {
            showSpinner(false);
            setCustomerUser(false);
            if (err.response && err.response.status === 404) {
              Swal.fire({
              title: intl.formatMessage({ id: 'module.sell.customer.qr.error.title' }),
              text: intl.formatMessage({ id: 'module.sell.customer.qr.error.notfound.text'}),
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: intl.formatMessage({ id: 'button.accept' }),
              });
            } else {
              Swal.fire({
                title: intl.formatMessage({id: 'module.sell.customer.qr.error.title'}),
                text: intl.formatMessage({id: 'module.sell.customer.qr.error.text'}, {error: err.response.status}),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: intl.formatMessage({ id: 'button.accept' }),
                });
            }            
            });
        });        
  };

  const handleCouponSearch = (qrCouponCode) => {

    const couponCode = encodeURIComponent(qrCouponCode);

    showSpinner(true);
    getApiClient()
        .then(apiClient => {
        apiClient
            .get('/v1/coupons/' + couponCode)
            .then((res) => res.data)
            .then((data) => {
              const validationMessage = validateCoupon(data);
              if (validationMessage == "") {
                  setCoupon(data);
              } else {
                  Swal.fire({
                      title:intl.formatMessage({ id: 'coupon.validation.title' }),
                      text: validationMessage,
                      icon: 'warning',
                      confirmButtonText: intl.formatMessage({ id: 'button.accept' })
                  });
              }
              showSpinner(false);
            })
            .catch(err => {
            showSpinner(false);
            setCoupon(false);
            if (err.response) {
                Swal.fire({
                title: intl.formatMessage({id: 'module.sell.coupon.qr.error.title'}),
                text: intl.formatMessage({id: 'module.sell.coupon.qr.error.text'}, {error: err.response.status}),
                icon: 'warning',
                showCancelButton: false,
                confirmButtonText: intl.formatMessage({ id: 'button.accept' }),
                });
            }
            });
        });
  };

  
  const validateCoupon = (coupon) => {

    if (coupon.expiryDate) {
      var expiryDate = new Date(coupon.expiryDate);
      var currentDate = new Date();

      var expiryDateString = expiryDate.toISOString().split('T')[0];
      var currentDateString = currentDate.toISOString().split('T')[0];

      if (expiryDateString < currentDateString) {
          return intl.formatMessage({ id: 'coupon.validation.expired' });
      }
    }

    if (coupon.usageLocation != 'webstore_and_pos' && coupon.usageLocation != 'pos') {
      return intl.formatMessage({ id: 'coupon.validation.notValidForPhysicalstore' });
    }

    if (coupon.excludeSaleItems === true && productsWithOffer === true) {
        return intl.formatMessage({ id: 'coupon.validation.excludeSaleItems' });
    }

    if (coupon.status != 'active') {
        return intl.formatMessage({ id: 'coupon.validation.notActive' });
    }

    if (coupon.minSpend != null && totalAmount < coupon.minSpend) {
        return intl.formatMessage({ id: 'coupon.validation.minimumSpendNotReached' }, { minSpend: coupon.minSpend });
    }

    if (coupon.includedProducts && coupon.includedProducts.length > 0) {
      const includedProductIds = typeof coupon.includedProducts === 'string' 
            ? coupon.includedProducts.split(',')
            : coupon.includedProducts;

      const productIdsInCart = listOfProductsToBuy.map(product => product.id.split('#')[0].toString());
      
      const allProductsMatch = productIdsInCart.length === includedProductIds.length &&
            productIdsInCart.every(productId => includedProductIds.includes(productId));

      if (!allProductsMatch) {
        return intl.formatMessage({ id: 'coupon.validation.notValidForAllProducts' });
      }
    }    

    return "";
  }

  function calculateTotalWithDiscount(totalAmount, coupon) {
    if (!coupon || Object.keys(coupon).length === 0) {
        return totalAmount;
    }

    let discountAmount = 0;

    if (coupon.discountType === 'percentage' && coupon.discountValue) {
        discountAmount = totalAmount * (coupon.discountValue / 100);
    } else if (coupon.discountType === 'fixed' && coupon.discountValue) {
        discountAmount = coupon.discountValue;
    }

    // Asegurarse de que el total no sea negativo
    const newTotal = Math.max(0, totalAmount - discountAmount);

    return newTotal;
  }


  const handleOnClickAddDescountButton = () => {
    const size = '40';
    const countDiscountapplied = listOfProductsToBuy.filter(product => product.id.split('#')[0] === discountProductID).length;

    if(calculateTotalWithDiscount(totalAmount, coupon) < 50000){
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.addDiscount.title'}),
        text: intl.formatMessage({id: 'module.sell.addDiscount.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });
    }else if(calculateTotalWithDiscount(totalAmount, coupon) >= 50000 && calculateTotalWithDiscount(totalAmount, coupon) < 100000 && countDiscountapplied == 1){
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.addDiscount.max.title'}),
        text: intl.formatMessage({id: 'module.sell.addDiscount.max.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });        
    }else if(calculateTotalWithDiscount(totalAmount, coupon) >= 100000 && calculateTotalWithDiscount(totalAmount, coupon) < 200000 && countDiscountapplied == 2){
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.addDiscount.max.title'}),
        text: intl.formatMessage({id: 'module.sell.addDiscount.max.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });        
    }else if(calculateTotalWithDiscount(totalAmount, coupon) >= 200000 && countDiscountapplied == 4){
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.addDiscount.max.title'}),
        text: intl.formatMessage({id: 'module.sell.addDiscount.max.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });
    }else{
      showSpinner(true);
      getApiClient().then(apiClient => {
        apiClient.get('/v1/products/'+discountProductID)
        .then((res) => {return res.data})      
        .then((data) => {
          showSpinner(false);
          addProductToListToBuy(data, size);
        })
        .catch(err => {
          console.log(err)
          showSpinner(false);
        })
      });      
    }
  }

  const handleOnClickDeleteProductButton = (productID) => {
    const productToDelete = listOfProductsToBuy.splice(listOfProductsToBuy.findIndex(product => {return product.id.split('#')[0] === productID;}), 1)[0];
    setTotalAmount(totalAmount-getProductPrice(productToDelete));
    setListOfProductsToBuy(current => [...current]);
    if (isProductOnDiscount(productToDelete)) {
      setProductsWithOffer(false);
    }
    setCoupon(false);
  }   

  const handleChangeSeller = (selectedOption) => {
    setSelectedSeller(selectedOption);
    setSelectSellerValid(true);
  };

  const handleChangePaymentMethod = (selectedOption) => {
    setSelectedPaymentMethod(selectedOption);
    setSelectPaymentMethodValid(true);
  };

  const handleOnClickPayButton = () => {
    if(!selectSellerValid || !selectPaymentMethodValid){
      setShowErrors(true);
      return;
    }    
    setShowModal(true);
  }

  // Create Order
  const handlePaymentData = (vouchers) => {
        
    order.products = listOfProductsToBuy;

    let voIndex = 1;
    for (let voucher of vouchers) {
      if(voucher.voucherCode != ''){
        (voIndex == 1) ? order[`voucherCode`] = voucher.voucherCode : order[`voucherCode${voIndex}`] = voucher.voucherCode;
        (voIndex == 1) ? order[`voucherCodeAmount`] = voucher.voucherCodeAmount : order[`voucherCode${voIndex}Amount`] = voucher.voucherCodeAmount;
        (voIndex == 1) ? order[`voucherCodePaymentMethod`] = voucher.paymentMethod.value : order[`voucherCode${voIndex}PaymentMethod`] = voucher.paymentMethod.value;
        (voIndex == 1) ? order[`paymentMethod`] = selectedPaymentMethod.value : null;
  
        voIndex++;
  
        if (voIndex > 3) break;
      }
    }

    order.totalAmount = calculateTotalWithDiscount(totalAmount, coupon);
    order.previousTotalAmount = (coupon || Object.keys(coupon).length !== 0) ? totalAmount : null;
    order.couponID = (coupon || Object.keys(coupon).length !== 0) ? coupon.id : null;
    order.location = getCurrentLocation().id;
    order.customerEmail = (customerUser) ? customerUser.email : getCurrentLocation().email;
    order.customerName = (customerUser) ? customerUser.name : getCurrentLocation().description;
    order.sellerEmail = selectedSeller.value;
    order.sellerName = selectedSeller.label;
    order.orderType = defaultOrderType;
    order.paymentGateway = defaultPaymentGateway;
    order.channel = channel;

    showSpinner(true);
    getApiClient().then(apiClient => {
      apiClient.post('/v2/orders', order)
      .then((res) => {return res.data})      
      .then((data) => {
        showSpinner(false);
        const orderID = data.id.split('#')[0];
        Swal.fire({
          title: intl.formatMessage({id: 'module.sell.payment.result.success.title'}),
          text: intl.formatMessage({id: 'module.sell.payment.result.success.text'}, {orderID: orderID}),
          icon: "success",
          showCancelButton: false,
          confirmButtonText: intl.formatMessage({id: 'button.ok'})
        });

        if (generateChangeTicket) {
          sendPdfToPrint(`/v2/orders/${orderID}/invoices/0/base64`);
        }        

        clearControls();
      })
      .catch(err => {
        console.log(err);
        showSpinner(false);
        Swal.fire({
          title: intl.formatMessage({id: 'module.sell.payment.result.error.title'}),
          text: intl.formatMessage({id: 'module.sell.payment.result.error.text'}, {error: err.response.status}),
          icon: "warning",
          showCancelButton: false,
          confirmButtonText: intl.formatMessage({id: 'button.accept'})
        });
      })
    });
  };


  const handleOnClickCancelButton = () => {
    clearControls();
  }

  function clearControls() {
    setTotalAmount(0);
    setListOfProductsToBuy([]);
    setBarcode('');
    setCustomerUser(false);
    setCoupon(false);
    setSelectedSeller('');
    setSelectedPaymentMethod('');
    setSelectSellerValid(false);
    setSelectPaymentMethodValid(false);
    setGenerateChangeTicket(false);
    setShowErrors(false);
    setProductsWithOffer(false);
  }

  const handlePasswordCorrect = () => {
    setIsAuthenticated(true);
  };

  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });

  function PriceList(props) {
    const listStyle = {
      'listStyle': 'none',
      'paddingLeft': 0
    };
  
    const prices = props.product.price;
    const productID = props.product.id.split('#')[0];

    const today = moment().format('YYYY-MM-DD');
    const specialFromDate = (props.product.specialFromDate !== null) ? props.product.specialFromDate.split('-')[2]+'-'+props.product.specialFromDate.split('-')[1]+'-'+props.product.specialFromDate.split('-')[0] : '';  
    const specialToDate = (props.product.specialToDate !== null) ? props.product.specialToDate.split('-')[2]+'-'+props.product.specialToDate.split('-')[1]+'-'+props.product.specialToDate.split('-')[0] : '';
    
    const priceItems = prices.map((price, index) =>
      <li key={productID+'-'+index}>
        <CurrencyFormat value={(price.value != null) ? price.value : 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} />
      </li>
    );
    priceItems.splice(1, 1);
    if(today >= specialFromDate && today <= specialToDate){
      priceItems[0] = <del key={priceItems[0].key+'-'+0} className="lh-1 text-alternate">{priceItems[0]}</del>;
    }else{
      priceItems.splice(1, 1);
    }

    return (
      <ul style={listStyle}>{priceItems}</ul>
    );
  }

  return (
    <>
      {!isAuthenticated ? (
        <PasswordModal onPasswordCorrect={handlePasswordCorrect} />
      ) : (
      <>
        <Row>
          <Col>
            <section className="scroll-section" id="title">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4">{title}</h1>
              </div>
            </section>
          </Col>

          <Col><SalesTrafficLight/></Col>
          
          <Col xs="12" lg="3" className="order-0 order-lg-1">
            <ProductSearchInput placeholder="Ej: 100123-40" onSearch={handleProductSearch} />
          </Col>
        </Row>
        
        <Row>
          <Col xs="12" className="col-lg order-1 order-lg-0">
            {/* Items Start */}
            <div className="mb-5">
              {listOfProductsToBuy.map((product, indexProduct) => {
                return <Card key={indexProduct} className="mb-2">
                  <Row className="g-0 sh-18 sh-md-14">
                    <Col xs="auto">
                      <img
                        alt={product.name}
                        src={(product.imageGallery.length) ? thumbsUrl+product.imageGallery[0].url : thumbsUrl+noImageFile}
                        className="card-img card-img-horizontal h-100 sw-8 sw-sm-10 sw-md-8"
                      />
                    </Col>
                    <Col className="position-relative h-100">
                      <Card.Body>
                        <Row className="g-0 h-100 align-content-center">
                          <Col xs="4" md="4" lg="4" className="d-flex flex-column mb-lg-0 mb-2 pe-1 d-flex order-1 h-lg-100 justify-content-center">
                            <div className="text-small text-muted text-truncate">{messages["module.sell.productToBuy.product.title"]}</div>
                              <div className="lh-1 text-alternate">{product.name}</div>
                              <div className="lh-1 text-alternate mt-1">{(product.id.split('#')[0] != discountProductID) ? product.id.split('#')[0] : ''}</div>
                          </Col>
                          <Col lg="4" className="d-flex flex-column pe-1 mt-0 mb-2 mb-lg-0 justify-content-center order-2">
                            <div className="text-small text-muted text-truncate">{messages["module.sell.productToBuy.size.title"]}</div>
                            <div className="lh-1 text-alternate">{product.items[0].attributes.find(({ type }) => type === "sizeNumber").value}</div>
                            <div className="lh-1 text-alternate">&nbsp;</div>
                          </Col>                      
                          <Col lg="4" className="d-flex flex-column pe-1 mb-2 mb-lg-0 justify-content-center order-3">
                            <div className="text-small text-muted text-truncate">{messages["module.sell.productToBuy.price.title"]}</div>
                            <PriceList product={product} />
                          </Col>
                        </Row>
                        <Button
                          size="sm"
                          className="btn-icon btn-icon-only position-absolute t-2 e-2"
                          variant="foreground-alternate"
                          onClick={() => handleOnClickDeleteProductButton(product.id.split('#')[0])}>
                          <CsLineIcons icon="error-hexagon" />
                        </Button>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              })}
            </div>
            {/* Items End */}
          </Col>

          {/* Checkout Start */}
          <Col xs="12" lg="3" className="order-0 order-lg-1">
            <Card className="w-100 sw-lg-35">
              <Card.Body>
                <div>
                  <div className="mb-2">
                    <p className="text-muted mb-1">{messages["module.sell.total.title"]}</p>
                      <div className="d-flex align-items-center">
                        <h1 className="mb-0 me-2">
                            <CurrencyFormat 
                                value={calculateTotalWithDiscount(totalAmount, coupon).toFixed()} 
                                displayType={'text'}
                                thousandSeparator={'.'}
                                decimalSeparator={','}
                                prefix={'$ '} 
                            />                      
                        </h1>
                        {coupon && Object.keys(coupon).length > 0 && (
                            <p className="text-muted mb-0" style={{ textDecoration: 'line-through' }}>
                                <CurrencyFormat 
                                    value={totalAmount.toFixed()} 
                                    displayType={'text'}
                                    thousandSeparator={'.'}
                                    decimalSeparator={','}
                                    prefix={'$ '} 
                                />                      
                            </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="separator-light mb-2" />
                <CustomerSearchInput customerUser={customerUser} onSearch={handleCustomerSearch} />
                <div className="separator-light mb-2" />
                <CouponSearchInput coupon={coupon} onSearch={handleCouponSearch} />
                <div className="separator-light mb-2" />
                <div className="d-grid gap-2 mb-3">
                  <Button className="btn-icon btn-icon-end" variant="secondary" size="s" onClick={handleOnClickAddDescountButton}>
                    <span><IntlMessages id="button.discount" /></span> <CsLineIcons icon="gift" />
                  </Button>
                </div>
                <div className="d-grid gap-2 mb-2">
                  <Select
                    name="seller"
                    options={store && sellers}
                    onChange={handleChangeSeller}
                    value={selectedSeller}
                    placeholder={messages["module.sell.selectSeller.placeholder"]}
                    isClearable={false}
                    isSearchable={false}
                  />
                  {(showErrors && !selectSellerValid && <Alert variant="danger">
                    {messages['module.sell.selectSeller.error.message']}
                  </Alert>)}
                </div>
                <div className="d-grid gap-2 mb-2">
                  <Select
                    name="paymentMethod"
                    options={paymentMethodForSelect}
                    onChange={handleChangePaymentMethod}
                    value={selectedPaymentMethod}
                    placeholder={messages["module.sell.selectPaymentMethod.placeholder"]}
                    isClearable={false}
                    isSearchable={false}
                  />
                  {(showErrors && !selectPaymentMethodValid && <Alert variant="danger">
                    {messages['module.sell.selectPaymentMethod.error.message']}
                  </Alert>)}
                </div>              
                <div className="d-grid gap-2 mb-3">
                  <Button className="btn-icon btn-icon-end" variant="primary" size="xl" onClick={handleOnClickPayButton}
                  disabled={!listOfProductsToBuy.length}>
                    <span><IntlMessages id="button.pay" /></span> <CsLineIcons icon="credit-card" />
                  </Button>
                  <Button className="btn-icon btn-icon-end" variant="danger" size="xl" onClick={handleOnClickCancelButton}>
                    <span><IntlMessages id="button.cancel" /></span> <CsLineIcons icon="close-circle" />
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <PaymentModalForm
          showModal={showModal}
          setShowModal={setShowModal}
          paymentMethod={selectedPaymentMethod}
          totalAmount={calculateTotalWithDiscount(totalAmount, coupon)}
          handlePaymentData={handlePaymentData} 
          generateChangeTicket={generateChangeTicket}
          setGenerateChangeTicket={setGenerateChangeTicket}
          />
      </>
      )}        
    </>
  );
};

export default injectIntl(SellModule);
