import moment from 'moment';
import { outOfStock } from 'constants/defaultValues';

  export const showSpinner = (option) => {
    if(option){
      document.body.classList.add('spinner');
    }else{
      document.body.classList.remove('spinner');
    }
  }

  export const mapOrder = (array, order, key) => {
    // eslint-disable-next-line func-names
    array.sort(function (a, b) {
      const A = a[key];
      const B = b[key];
      if (order.indexOf(`${A}`) > order.indexOf(`${B}`)) {
        return 1;
      }
      return -1;
    });
    return array;
  };
  
  export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!
  
    const yyyy = today.getFullYear();
    if (dd < 10) {
      dd = `0${dd}`;
    }
    if (mm < 10) {
      mm = `0${mm}`;
    }
    return `${dd}.${mm}.${yyyy}`;
  };
  
  export const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
  };

  export const getFormatDate = (date, format) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    switch (format) {
      case "yyyy-mm-dd":
        return `${year}-${month}-${day}`;

      case "yyyymmdd":
        return `${year}${month}${day}`;        
    
      default:
        return `${day}-${month}-${year}`
    }
  };
    
  export const getCurrentUser = () => {
/*     let user = null;
    try {
      user =
        localStorage.getItem('dueidee_current_user') != null
          ? JSON.parse(localStorage.getItem('dueidee_current_user'))
          : null;
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js  : getCurrentUser -> error', error);
      user = null;
    }
    return user;
 */  };
  
  export const setCurrentUser = (user) => {
/*     try {
      if (user) {
        localStorage.setItem('dueidee_current_user', JSON.stringify(user));
      } else {
        localStorage.removeItem('dueidee_current_user');
      }
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js : setCurrentUser -> error', error);
    }
 */  };
  
  export const getOauthToken = () => {
   let oauthToken = null;
    try {
       oauthToken =
        localStorage.getItem('dueidee_current_oauth_token') != null
          ? JSON.parse(localStorage.getItem('dueidee_current_oauth_token'))
          : null;
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js  : setOauthToken -> error', error);
    }
    return oauthToken;
  };
  
  export const setOauthToken = (oauthToken) => {
    try {
      if (oauthToken) {
        localStorage.setItem('dueidee_current_oauth_token', JSON.stringify(oauthToken));
      } else {
        localStorage.removeItem('dueidee_current_oauth_token');
      }
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js : setOauthToken -> error', error);
    }
  };

  export const getProductPrice = (product, orderDate) => {
    const today = moment().format("YYYY-MM-DD");
  
    const formatSpecialDate = (date) => {
      return date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : null;
    };
  
    const specialFromDate = formatSpecialDate(product.specialFromDate);
    const specialToDate = formatSpecialDate(product.specialToDate);
  
    const getPrice = (type) => {
      const priceObj = product.price.find(({ type: t }) => t === type);
      return priceObj && priceObj.value != null ? priceObj.value : 0;
    };
  
    if (orderDate) {
      const orderDateParsed = moment(orderDate).format("YYYY-MM-DD");
      if (specialFromDate && specialToDate && orderDateParsed >= specialFromDate && orderDateParsed <= specialToDate) {
        return getPrice("special");
      }
      return getPrice("normal");
    } else {
      if (specialFromDate && specialToDate && today >= specialFromDate && today <= specialToDate) {
        return getPrice("special");
      }
      return getPrice("normal");
    }
  };

  export const isProductOnDiscount = (product) => {
    const today = moment().format('YYYY-MM-DD');

    const specialFromDate = product.specialFromDate ? moment(product.specialFromDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';
    const specialToDate = product.specialToDate ? moment(product.specialToDate, 'DD-MM-YYYY').format('YYYY-MM-DD') : '';

    return today >= specialFromDate && today <= specialToDate;
  };

  export const getCurrentLocation = () => {
    let location = null;
     try {
      location =
        localStorage.getItem('dueidee_current_location') != null
           ? JSON.parse(localStorage.getItem('dueidee_current_location'))
           : null;
     } catch (error) {
       console.log('>>>>: src/helpers/Utils.js  : getCurrentLocation -> error', error);
     }
     return location;
   };

   export const setCurrentLocation = (location) => {
    try {
      if (location) {
        localStorage.setItem('dueidee_current_location', JSON.stringify(location));
      } else {
        localStorage.removeItem('dueidee_current_location');
      }
    } catch (error) {
      console.log('>>>>: src/helpers/Utils.js : setCurrentLocation -> error', error);
    }
  };

  export const getLocationNameFromID = (locationID) => {
     return locationID.split("#")[1].replace(/\./g, " ").toLowerCase().replace(/^\w|\s\w/g, match => match.toUpperCase());
  };

  export function filterProductItemsBySize(items, size){
    const currentLocationId = getCurrentLocation().id;

    return items.filter((item) => 
        item.attributes.find(({ type }) => type === "sizeNumber").value === size
        && item.stockStatus !== outOfStock
        && item.location === currentLocationId
      )
  };

  export const getCurrentNotifications = () => {
    let notifications = [];
    try {
      notifications =
        localStorage.getItem('point_of_sale_notifications') !== null
          ? JSON.parse(localStorage.getItem('point_of_sale_notifications'))
          : [];
    } catch (error) {
      console.log('Error fetching notifications: ', error);
    }
    return notifications;
  };

  export const getNotificationByPath = (path) => {
    const notifications = getCurrentNotifications();
    const notification = notifications.find((n) => n.path === path);
    return notification || null;
  };  

  export const getTotalNotificationsForPath = (path) => {
    const notifications = getCurrentNotifications();
    const totalNotifications = notifications
      .filter((n) => n.path.includes(path))
      .reduce((total, n) => total + n.count, 0);
    return totalNotifications;
  };
  
  export const addNotification = (notification) => {
    try {
      const notifications = getCurrentNotifications();
      const existingNotificationIndex = notifications.findIndex((n) => n.path === notification.path);
  
      if (existingNotificationIndex !== -1) {
        notifications[existingNotificationIndex].count += notification.count;
      } else {
        notifications.push(notification);
      }
  
      localStorage.setItem('point_of_sale_notifications', JSON.stringify(notifications));
    } catch (error) {
      console.log('Error adding notification: ', error);
    }
  };
  
  export const clearNotifications = () => {
    try {
      localStorage.removeItem('point_of_sale_notifications');
    } catch (error) {
      console.log('Error clearing notifications: ', error);
    }
  };

  export const clearNotificationsByPath = (path) => {
    try {
      const notifications = getCurrentNotifications();
      const filteredNotifications = notifications.filter((n) => n.path !== path);
      localStorage.setItem('point_of_sale_notifications', JSON.stringify(filteredNotifications));
    } catch (error) {
      console.log('Error clearing notifications: ', error);
    }
  };  
  