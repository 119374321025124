import { useEffect, useContext } from 'react';
import { backendApiUrl } from 'constants/defaultValues';
import { SSEContext } from './SSEContext';
import { addNotification, getCurrentLocation, getCurrentNotifications } from 'helpers/Utils';

const useSSE = () => {
    const { badge, setBadge } = useContext(SSEContext);

    useEffect(() => {
        if (typeof EventSource !== 'undefined') {
            let location = getCurrentLocation();
            
            if (location == null) return;

            location = location.id.split('#').join('.')

            const backEndUrl = new EventSource(`${backendApiUrl}/v1/event-streams/${encodeURIComponent(location)}`);
            const listener = 'com.lovleis.' + location + '.notification';

            backEndUrl.addEventListener(listener, (event) => {
                const notificationData = JSON.parse(event.data);
                addNotification(JSON.parse(notificationData));

                const notifications = getCurrentNotifications();

                notifications.forEach((notification) => {
                  setBadge(notification);
                });                
            });
        } else {
            console.log('Sorry, your browser does not support server-sent events...');
        }
    }, []);

    return badge;
};

export default useSSE;
