export const appVersion = process.env.REACT_APP_VERSION;

export const defaultAdminPassword = '12345';

export const defaultDomainStore = 'lovleis.com';
export const defaultWarehouseLocationID = 'warehouse#bodega.alsacia#depto.303';
export const defaultMarketplaceCurrency = 'CLP';
export const defaultOrderType = 'in_store_purchase';
export const defaultPaymentGateway = 'bciPagos';

export const currencyExchangeRateURL = 'https://api.dueidee.cl/v1/data/monetray-indexes';
export const validationCouponURL = 'https://www.lovleis.com/validate-coupon';

export const backendOauthUrl = process.env.REACT_APP_BACKEND_OAUTH_URL;
export const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
export const clientID = process.env.REACT_APP_CLIENT_ID;

export const thumbsUrl = 'https://s3.sa-east-1.amazonaws.com/dueidee.cl/stores/lovleis.com/thumbs/';
export const noImageFile = 'no-image.png';

export const shippingCompanyUrl = [
  {name: 'chilexpress', url: 'https://centrodeayuda.chilexpress.cl/seguimiento/'},
  {name: 'starken', url: 'https://www.starken.cl/seguimiento?codigo='},
  {name: 'paket', url: 'https://track.paket.cl/'},
  {name: 'bluexpress', url: 'https://tracking-unificado.blue.cl/?n_seguimiento='},
];

export const months = [
  {id: '01', name: 'January'},
  {id: '02', name: 'February'},
  {id: '03', name: 'March'},
  {id: '04', name: 'April'},
  {id: '05', name: 'May'},
  {id: '06', name: 'June'},
  {id: '07', name: 'July'},
  {id: '08', name: 'August'},
  {id: '09', name: 'September'},
  {id: '10', name: 'October'},
  {id: '11', name: 'November'},
  {id: '12', name: 'December'}
]

export const paymentMethod = [
    {id: 'DEBITO', name: 'DEBITO'},
    {id: 'CREDITO', name: 'CREDITO'},
    {id: 'EFECTIVO', name: 'EFECTIVO'},
    {id: 'MIXTO', name: 'MIXTO'},
]

export const paymentMethodForSelect = paymentMethod.map(({ id, name }) => ({ label:id, value:name }));

export const discountProductID = '100117';
export const outOfStock = 'out_of_stock';
export const channel = 'store';

export const IVA = 1.19;

export const daysExceededLimit = 10;