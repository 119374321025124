import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import getApiClient from 'api/client';
import { showSpinner, getCurrentLocation } from 'helpers/Utils';

function SalesTrafficLight({intl}) {    
    const [trafficLightIcon, setTrafficLightIcon] = useState('/img/components/traffic-light/horizontal-traffic-light-icon-red.png');
    const [saleGap, setSaleGap] = useState(0);

    const { messages } = intl;

    const storeLocation = getCurrentLocation().id;

    useEffect(() => {
        showSpinner(true);

        getApiClient()
            .then(apiClient => {
                apiClient.get('/v1/reports?type=data-for-dashboards&store-location=' + encodeURIComponent(storeLocation))
                .then(res => {
                    const kpiFinancialGoal = res.data._embedded.reports[0].dataForDashboards.kpiFinancialGoal;
                    calculateTrafficLight(kpiFinancialGoal);
                    showSpinner(false);
                })
                .catch(err => {
                    showSpinner(false);
                    console.log(err);
                });
            });
    }, []);

    const calculateTrafficLight = (kpiFinancialGoal) => {
        var date = new Date();
        var currentYear = date.getFullYear();
        var currentMonth = date.getMonth() + 1;
        var currentDay = date.getDate();
        var thresholdLimit = 5;

        var daysInCurrentMonth = new Date(currentYear, currentMonth, 0).getDate();

        var expectedSales = Math.round((kpiFinancialGoal.breakeven / daysInCurrentMonth) * currentDay);
        var saleGapValue = (kpiFinancialGoal.totalSellQuantity - expectedSales);
        setSaleGap(saleGapValue);

        if (kpiFinancialGoal.totalSellQuantity >= expectedSales) {
            setTrafficLightIcon('/img/components/traffic-light/horizontal-traffic-light-icon-green.png');
        } else if (kpiFinancialGoal.totalSellQuantity >= (expectedSales - thresholdLimit)) {
            setTrafficLightIcon('/img/components/traffic-light/horizontal-traffic-light-icon-yellow.png');
        } else {
            setTrafficLightIcon('/img/components/traffic-light/horizontal-traffic-light-icon-red.png');
        }
    };

    return (
        <div>
            <img src={trafficLightIcon} alt="Traffic Light" width="100px"/>&nbsp;
            {messages["module.sell.trafficlight.title"]}: {saleGap}
        </div>
    );
}

export default injectIntl(SalesTrafficLight);
