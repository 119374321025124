import React, { useEffect, useState, useRef } from 'react';
import { Col, Button } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import IntlMessages from 'helpers/IntlMessages';

const ProductSearchInput = ({ placeholder, onSearch }) => {
  const [barcode, setBarcode] = useState('');
  const [isSearchActive, setIsSearchActive] = useState(false); // Nuevo estado
  const inputSearchRef = useRef(null);
  const inactivityTimer = useRef(null);

  useEffect(() => {
    if (isSearchActive) {
      inputSearchRef.current.focus();
    }
  }, [isSearchActive]);

  const onChangeSearchTerm = (val) => {
    setBarcode(val);

    // Restablecer el temporizador de inactividad cada vez que haya un cambio
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(() => {
      setIsSearchActive(false);
    }, 30000);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSearch(barcode);
      setBarcode('');
    }
  };

  const activateSearch = () => {
    setIsSearchActive(true);
    
    clearTimeout(inactivityTimer.current);
    inactivityTimer.current = setTimeout(() => {
      setIsSearchActive(false);
    }, 30000);
  };

  return (
    <Col xs="12" lg="3" className="order-0 order-lg-1">
      {isSearchActive ? (
        <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 shadow bg-foreground sw-lg-35">
          <div className="top-label">
            <label>{placeholder}</label>
          </div>
          <input
            id="searchPagesInput"
            name="searchPagesInput"
            className="form-control borderless mt-2 py-3 rounded-lg"
            type="text"
            autoComplete="off"
            onChange={(e) => onChangeSearchTerm(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            value={barcode}
            placeholder={placeholder}
            ref={inputSearchRef}
          />
          <span className="search-magnifier-icon">
            <CsLineIcons icon="search" className="mt-4 text-muted" />
          </span>
        </div>
      ) : (
        <div className="d-grid gap-2 mb-3">
          <Button className="btn-icon btn-icon-end" size="xl" onClick={activateSearch}>
            <span><IntlMessages id="button.scan" /></span> <CsLineIcons icon="tag" />
          </Button>
        </div>        
      )}
    </Col>
  );
};

export default ProductSearchInput;
