import getApiClient from 'api/client';

export const createPDFdata = async (pdfUrl) => {
    try {
        const apiClient = await getApiClient();
        const response = await apiClient.get(pdfUrl);
        return response.data.invoiceB64;
    } catch (error) {
        console.error("Error fetching PDF:", error);
    }
};

export const sendPdfToPrint = async (data_or_url, isBase64 = false) => {
    let pdfData;
    if (isBase64) {
        pdfData = data_or_url; // Si es base64, simplemente usamos los datos proporcionados
    } else {
        pdfData = await createPDFdata(data_or_url); // De lo contrario, obtenemos el PDF desde el servidor
    }

    const appscheme = 'tmprintassistant://';
    const host = 'tmprintassistant.epson.com/';
    const action = 'print?';
    const query_ver = 'ver';
    const query_datatype = 'data-type';
    const query_data = 'data';
    const query_reselect = 'reselect';
    const query_cut = 'cut';
    const query_fittowidth = 'fit-to-width';
    const query_paperwidth = 'paper-width';    
    const ver = '1';
    
    const datatype = 'pdf';
    const reselect = 'yes';
    const cut = 'feed';
    const fittowidth = 'yes';
    const paperwidth = '80';

    const urlData = `${appscheme}${host}${action}&${query_ver}=${ver}&${query_datatype}=${datatype}&${query_data}=${encodeURIComponent(pdfData)}&${query_reselect}=${reselect}&${query_cut}=${cut}&${query_fittowidth}=${fittowidth}&${query_paperwidth}=${paperwidth}`;
    window.location.href = urlData;
};