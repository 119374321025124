import { lazy } from 'react';
import { DEFAULT_PATHS } from 'config.js';

import SellModule from 'views/sell/Sell';
import SettingsModule from 'views/settings/Settings';

const changesAndReturns = {
  change: lazy(() => import('views/change/Change')),
  return : lazy(() => import('views/return/Return')),

}

const ecommerce = {
  delivery: lazy(() => import('views/ecommerce/delivery/EcommerceDelivery')),
  deliveryorderdetail: lazy(() => import('views/ecommerce/delivery/EcommerceDeliveryOrderDetail')),
  clickAndCollect : lazy(() => import('views/ecommerce/clickandcollect/EcommerceClickAndCollect')),
  clickAndCollectorderdetail: lazy(() => import('views/ecommerce/clickandcollect/EcommerceClickAndCollectOrderDetail')),
};

const goods = {
  movements: lazy(() => import('views/goods/movements/GoodsMovements')),
};

const report = {
  salesofday: lazy(() => import('views/report/salesofday/ReportSalesOfDay')),
  salesofdayorderdetail: lazy(() => import('views/report/salesofday/ReportSalesOfDayOrderDetail')),
  binnacle: lazy(() => import('views/report/binnacle/ReportBinnacle')),
  stockinstore: lazy(() => import('views/report/stockinstore/ReportStockInStore')),
};


const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/sell`,
    },
    {
      path: `${appRoot}/sell`,
      component: SellModule,
      label: 'menu.module.sell.title',
      icon: 'dollar',
    },
    {
      path: `${appRoot}/change-and-return`,
      label: 'menu.module.change-and-return.title',
      icon: 'refresh-horizontal',
      subs: [
        { path: '/change', label: 'menu.module.change.title', component: changesAndReturns.change },
        { path: '/return', label: 'menu.module.return.title', component: changesAndReturns.return },
      ],
    },    

    {
      path: `${appRoot}/ecommerce`,
      label: 'menu.module.ecommerce.title',
      icon: 'cart',
      subs: [
        { path: '/delivery', 
          label: 'menu.module.ecommerce.delivery.title', 
          component: ecommerce.delivery,
          subs: [
            { path: '/orderdetail/:orderId', component: ecommerce.deliveryorderdetail, hideInMenu: true },
          ]
        },
        { path: '/clickandcollect',
          label: 'menu.module.ecommerce.clickandcollect.title',
          component: ecommerce.clickAndCollect,
          subs: [
            { path: '/orderdetail/:orderId', component: ecommerce.clickAndCollectorderdetail, hideInMenu: true },
          ]
        },
      ],
    },
    {
      path: `${appRoot}/goods`,
      label: 'menu.module.goods.title',
      icon: 'archive',
      subs: [
        { path: '/movements', label: 'menu.module.goods.movements.title', component: goods.movements },
      ],
    },    
    {
      path: `${appRoot}/report`,
      label: 'menu.module.report.title',
      icon: 'file-text',
      subs: [
        { path: '/salesofday', 
          label: 'menu.module.report.salesofday.title', 
          component: report.salesofday,
          subs: [
            { path: '/orderdetail/:orderId', component: report.salesofdayorderdetail, hideInMenu: true },
          ]
        },
        { path: '/binnacle', label: 'menu.module.report.binnacle.title', component: report.binnacle },
        { path: '/stockinstore', label: 'menu.module.report.stockinstore.title', component: report.stockinstore },
      ],
    },  
    {
      path: `${appRoot}/settings`,
      component: SettingsModule,
    },      
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
