import React, { useState } from 'react';
import { Modal, Button, Form, Alert} from 'react-bootstrap';
import { injectIntl } from 'react-intl';
import IntlMessages from 'helpers/IntlMessages';
import {Buffer} from 'buffer';
import { backendOauthUrl, clientID, defaultDomainStore } from 'constants/defaultValues';

const PasswordModal = ({ onPasswordCorrect, intl }) => {
  const [key, setKey] = useState('');
  const [error, setError] = useState(false);

  const onSubmit = async () => {
    const mfaUrl = backendOauthUrl + '/v1/login/mfa';
    try {
      const formData = new FormData();
      formData.append('code', key);
      formData.append('origin', clientID);

      const response = await fetch(mfaUrl, {
        method: 'POST',
        headers: {
          'due-idee-domain-store': defaultDomainStore,
        },
        body: formData
      });

      if (response.status === 200) {
        localStorage.setItem("isAuthenticated", "true");
        onPasswordCorrect();
      } else if (response.status === 401) {
        setError(intl.formatMessage({id: 'module.sell.passwordmodal.password.error.label'}));
      } else {
        setError(intl.formatMessage({id: 'module.sell.passwordmodal.oauth.error.label'}));
      }
    } catch (error) {
        console.log(error);
      setError(intl.formatMessage({id: 'module.sell.passwordmodal.oauth.error.label'}));
    }
  };

  return (
    <Modal backdrop="static" keyboard={false} show={true}>
        <Modal.Header>
            <Modal.Title>{intl.formatMessage({id: 'module.sell.passwordmodal.title'})}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Label>{intl.formatMessage({id: 'module.sell.passwordmodal.password.label'})}</Form.Label>
            <Form.Control
                type="password"
                name="password"
                value={key}
                onChange={e => setKey(e.target.value)}
            />        
        </Modal.Body>
        <Modal.Footer>
            {error && <Alert variant='danger'>{error}</Alert>}
            <Button className="btn-icon btn-icon-end" variant="primary" size="xl" onClick={onSubmit}>{intl.formatMessage({id: 'button.accept'})}</Button>
        </Modal.Footer>
    </Modal>
  );
};

export default injectIntl(PasswordModal);
