import React, { useEffect, useState, useRef } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import IntlMessages from 'helpers/IntlMessages';
import { injectIntl } from 'react-intl';

const CustomerSearchInput = ({ customerUser, onSearch, intl }) => {
    const [qrCustomerCode, setQRCustomerCode] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false); // Nuevo estado para controlar la visibilidad
    const inputCustomerSearchRef = useRef(null);

  useEffect(() => {
    if (isSearchActive) {
        inputCustomerSearchRef.current.focus();
    }
  }, [isSearchActive]);

  useEffect(() => {
    setIsSearchActive(false);
  }, [customerUser]);

  const onChangeSearchTerm = (val) => {
    setQRCustomerCode(val);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSearch(qrCustomerCode);
      setQRCustomerCode('');
    }
  };

  const onCancel = (val) => {
    setIsSearchActive(false);
  };

  const activateSearch = () => {
    setIsSearchActive(true);
  };

  return (
    <div>
      {!customerUser && !isSearchActive && (
        <div className="d-grid gap-2 mb-2">
        <Button className="btn-icon btn-icon-end" variant="light" size="s" onClick={activateSearch}>
            <span><IntlMessages id="module.sell.customer.qr.button" /></span> <CsLineIcons icon="user" />
          </Button>
        </div>
      )}
      {isSearchActive && (
        <div className="me-1 mb-2 shadow bg-foreground d-flex align-items-center">
          <input
            id="searchCustomerInput"
            name="searchCustomerInput"
            className="form-control"
            type="text"
            autoComplete="off"
            onChange={(e) => onChangeSearchTerm(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            value={qrCustomerCode}
            ref={inputCustomerSearchRef}
            style={{ flex: 1 }}
            placeholder={intl.formatMessage({ id: 'general.text.email.label' })}
          />
          <Button className="btn-icon btn-icon-end ms-2" variant="danger" size="s" onClick={onCancel}>
            <CsLineIcons icon="close-circle" />
          </Button>
        </div>
      )}
      {customerUser && (
        <div className="mb-4">
            <div className="text-small ps-6 h-100">
                <strong>{<IntlMessages id="module.sell.customer.title" />}</strong>
            </div>
            <Row className="g-0 sh-6">
                <Col xs="auto">
                    <img
                        src={`/img/icons/icono-lovme-${customerUser.category ? customerUser.category.toLowerCase() : 'glam'}.png`}
                        className="card-img rounded-xl sh-8 sw-8"
                        alt="thumb"
                    />
                </Col>
                <Col>
                    <div className="d-flex flex-row ps-3 h-100 align-items-center justify-content-between">
                        <div className="d-flex flex-column">
                            <div>{customerUser.name}</div>
                            <div className="text-small text-muted">{customerUser.email}</div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
      )}
    </div>
  );
};

export default injectIntl(CustomerSearchInput);
