import axios from "axios";
import { getOauthToken, setOauthToken } from "helpers/Utils";
import { defaultDomainStore, backendApiUrl, backendOauthUrl, clientID } from 'constants/defaultValues';
import {Buffer} from 'buffer';

const domainStore = defaultDomainStore;

async function signInApi () {
  const signInUrl = backendOauthUrl+'/oauth';

  return await fetch(signInUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      'Authorization': 'Basic ' + new Buffer.from(clientID+':'+clientID).toString('base64'),
    },
    body: new URLSearchParams({'grant_type': 'client_credentials'})
  })
    .then(response => response.json())
    .then(json => json)
    .catch((error) => { throw error })
}

async function refreshAuthToken() {
  const oauth = await Promise.resolve(signInApi());;
  return oauth;
}

async function getApiClient() {
  let token;
  if (getOauthToken() != null) {
    token = getOauthToken().access_token;
  } else {
    const oauth = await Promise.resolve(signInApi());;
    token = oauth.access_token;
    setOauthToken(oauth);
  }
  
  const apiClient = axios.create({
    baseURL: backendApiUrl,
    headers: {
      Authorization: 'Bearer ' + token,
      'due-idee-domain-store': domainStore
    }  
  });

  apiClient.interceptors.response.use(
    response => response, // Si la respuesta es exitosa, simplemente la devolvemos
    async error => {
      if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        // Si obtenemos un error 401, intentamos refrescar el token
        error.config.__isRetryRequest = true;
        const oauth = await refreshAuthToken();
        apiClient.defaults.headers.Authorization = 'Bearer ' + oauth.access_token;
        error.config.headers.Authorization = 'Bearer ' + oauth.access_token;
        setOauthToken(oauth);
        return apiClient(error.config);
      }
      
      // Si el error no es un 401, o si ya hemos intentado refrescar el token, simplemente rechazamos la promesa
      return Promise.reject(error);
    }
  );

  return apiClient;
}


export default getApiClient; 