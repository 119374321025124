import React, { useEffect, useState, useRef } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import IntlMessages from 'helpers/IntlMessages';
import { injectIntl } from 'react-intl';
import CurrencyFormat from 'react-currency-format';
import { validationCouponURL } from 'constants/defaultValues';

const CouponSearchInput = ({ coupon, onSearch, intl }) => {
    const [qrCouponCode, setQRCouponCode] = useState('');
    const [isSearchActive, setIsSearchActive] = useState(false); 
    const inputCouponSearchRef = useRef(null);

  useEffect(() => {
    if (isSearchActive) {
        inputCouponSearchRef.current.focus();
    }
  }, [isSearchActive]);

  useEffect(() => {
    setIsSearchActive(false);
  }, [coupon]);

  const onChangeSearchTerm = (val) => {
    setQRCouponCode(val);
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      onSearch(qrCouponCode);
      setQRCouponCode('');
    }
  };

  const onCancel = (val) => {
    setIsSearchActive(false);
  };

  const activateSearch = () => {
    setIsSearchActive(true);
  };

  const getCouponUsageLocationText = (couponUsageLocation) => {
    switch (couponUsageLocation) {
        case 'pos':
            return intl.formatMessage({ id: 'coupon.usageLocation.pos' });
        case 'webstore':
            return intl.formatMessage({ id: 'coupon.usageLocation.webstore' });
        case 'webstore_and_pos':
            return intl.formatMessage({ id: 'coupon.usageLocation.posAndWebstore' });
        default:
            return '';
    }
};


  return (
    <div>
      {!coupon && !isSearchActive && (
        <div className="d-grid gap-2 mb-2">
        <Button className="btn-icon btn-icon-end" variant="light" size="s" onClick={activateSearch}>
            <span><IntlMessages id="module.sell.coupon.qr.button" /></span> <CsLineIcons icon="diploma" />
          </Button>
        </div>
      )}
      {isSearchActive && (
        <div className="me-1 mb-2 shadow bg-foreground d-flex align-items-center">
          <input
            id="searchCustomerInput"
            name="searchCustomerInput"
            className="form-control"
            type="text"
            autoComplete="off"
            onChange={(e) => onChangeSearchTerm(e.target.value)}
            onKeyDown={(e) => onKeyDown(e)}
            value={qrCouponCode}
            ref={inputCouponSearchRef}
            style={{ flex: 1 }}
            placeholder={intl.formatMessage({ id: 'general.text.coupon.label' })}
          />
          <Button className="btn-icon btn-icon-end ms-2" variant="danger" size="s" onClick={onCancel}>
            <CsLineIcons icon="close-circle" />
          </Button>
        </div>
      )}
      {coupon && (
        <div className="mb-2">
            <Row>
                <Col>
                    <div className="d-flex flex-row ps-1 h-100 align-items-center justify-content-between">
                      <div className="d-flex flex-column">
                        <div>
                          <IntlMessages id="module.sell.coupon.title" />:{' '}
                          <a href={`${validationCouponURL}?code=${coupon.couponCode}`} target="_blank" rel="noopener noreferrer">
                              {coupon.couponCode}
                          </a>
                        </div>
                        <div className="text-small text-muted">
                            {coupon.discountType === 'percentage' ? 
                                `${coupon.discountValue}%` : 
                                (
                                    <CurrencyFormat 
                                        value={coupon.discountValue} 
                                        displayType={'text'}
                                        thousandSeparator={'.'}
                                        decimalSeparator={','}
                                        prefix={'$ '}
                                    />
                                )
                            }
                            {' | '}
                            {getCouponUsageLocationText(coupon.usageLocation)}
                        </div>
                    </div>
                    </div>
                </Col>
            </Row>
        </div>
      )}
    </div>
  );
};

export default injectIntl(CouponSearchInput);
