import React, { useEffect, useState } from 'react';
import { useIntl, FormattedMessage } from 'react-intl'; // Importamos useIntl y FormattedMessage
import { Row, Col, Card, Button } from 'react-bootstrap';
import useCustomLayout from 'hooks/useCustomLayout';
import { defaultAdminPassword, backendApiUrl } from 'constants/defaultValues';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import { setCurrentLocation } from 'helpers/Utils';
import getApiClient from 'api/client';
import { showSpinner } from 'helpers/Utils';

const SettingsModule = () => {
  const { formatMessage } = useIntl(); // Modificamos para desestructurar formatMessage
  const [locations, setLocations] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Definimos los mensajes y sus traducciones
  const messages = {
    title: formatMessage({ id: 'settings.page.title' }),
    enterPassword: formatMessage({ id: 'settings.enter_password' }),
    incorrectPassword: formatMessage({ id: 'settings.incorrect_password' }),
  };

  // Get Store data
  useEffect(() => {
    if (isAuthenticated) {
      showSpinner(true);
      getApiClient()
        .then(apiClient => {
          apiClient
            .get('/v1/stores/lovleis.com')
            .then(res => {
              return res.data;
            })
            .then(data => {
              setLocations(data.locations);
              showSpinner(false);
            })
            .catch(err => {
              setLoadingLocation(false);
              showSpinner(false);
            });
        });
    }
  }, [isAuthenticated]);

  useCustomLayout({ placement: MENU_PLACEMENT.Horizontal, layout: LAYOUT.Boxed });

  const extractStoreName = location => {
    const parts = location.id.split('#');
    const name = parts[1];
    return name.replace(/\./g, ' ').replace(/\b\w/g, firstLetter => firstLetter.toUpperCase());
  };

  const handleLocationChange = location => {
    setCurrentLocation(location);
    window.location.reload();
  };

  const checkPassword = () => {
    const password = prompt(messages.enterPassword);
    if (password === defaultAdminPassword) {
      setIsAuthenticated(true);
    } else {
      alert(messages.incorrectPassword);
    }
  };

  return (
    <>
      <Row>
        <Col>
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <h1 className="mb-0 pb-0 display-4">{messages.title}</h1>
            </div>
            <Card className="mb-5" body>
              <p>URL: {backendApiUrl}</p>
              {isAuthenticated && (
                <div>
                  {locations
                    .filter(location => location.type === 'store')
                    .map((location, index) => (
                      <Button
                        key={index}
                        variant="primary"
                        onClick={() => handleLocationChange(location)}
                        style={{ display: 'block', marginBottom: '10px' }}
                      >
                        {extractStoreName(location)}
                      </Button>
                    ))}
                </div>
              )}
              {!isAuthenticated && (
                <Button variant="primary" onClick={checkPassword}>
                  <FormattedMessage id="settings.enter_password" defaultMessage="Enter Password" />
                </Button>
              )}
            </Card>
          </section>
        </Col>
      </Row>
    </>
  );
};

export default SettingsModule;
