const enMessages = {
 /* GENERAL TEXT */
  /****************/
  'general.text.email.label': 'Email',
  'general.text.coupon.label': 'Código de Cupón',

  /* COUPON */
  /**********/
  'coupon.usageLocation.pos': 'Sólo compras en tienda física',
  'coupon.usageLocation.webstore': 'Sólo compras online',
  'coupon.usageLocation.posAndWebstore': 'Válido Online y tienda física',

  'coupon.validation.title': 'ATENCIÓN',
  'coupon.validation.excludeSaleItems': 'El cupón excluye productos en oferta.',
  'coupon.validation.expired': 'El cupón está vencido.',
  'coupon.validation.notActive': 'El cupón ya fue usado.',
  'coupon.validation.notValidForPhysicalstore': 'El cupón no es válido para compras en la tienda física.',
  'coupon.validation.minimumSpendNotReached': 'El cupón aplica para compras sobre {minSpend}.',
  'coupon.validation.notValidForProducts': 'El cupón no aplica para los productos ingresados.',
  'coupon.validation.notValidForAllProducts': 'El cupón es para productos específicos, no aplica para los productos ingresados.',


 
  /* GENERAL MESSAGES */
  /********************/
  'general.message.loading.text': 'Cargando datos...',
  'general.message.rotate.text': 'Por favor, gira el dispositivo a modo horizontal...',
  'general.message.workinprogress.text': 'Se habilitará Próximamente',



  /* STATES */
  /**********/
  'state-uninitiated': 'No Iniciada',
  'state-rejected': 'Rechazada',
  'state-returned': 'Devuelta',
  'state-partial-returned': 'Devolución Parcial',
  'state-pending': 'Pendiente',
  'state-payment-confirmed': 'Pago Confirmado',
  'state-in-revision': 'En revisión de Stock',
  'state-in-transit': 'Productos Enviados',
  'state-in-wishlist': 'En Favoritos',
  'state-in-cart': 'En Carro de Compras',
  'state-delivered': 'Productos Entregados',
  'state-success': 'Finalizada',
  'state-in-progress': 'En progreso',
  'state-warning': 'Advertencia',

  'state-cc-confirmed': 'Retiro en Tienda - Compra Confirmada',
  'state-cc-preparation': 'Retiro en Tienda - En Preparación',
  'state-cc-ready': 'Retiro en Tienda - Listo para Recoger',
  'state-cc-collected': 'Retiro en Tienda - Recogido por Cliente',
  'state-cc-not-collected': 'Retiro en Tienda - No Recogido',

  /* PAYMENT METHODS */
  /*******************/

  'payment-method-debit': 'Débito',
  'payment-method-credit': 'Crédito',
  'payment-method-cash': 'Efectivo',
  'payment-method-mixed': 'Mixto',
  
  /* BUTTONS */
  /***********/
  'button.search': 'BUSCAR',
  'button.pay': 'PAGAR',
  'button.accept': 'ACEPTAR',
  'button.cancel': 'CANCELAR',
  'button.close': 'CERRAR',
  'button.confirm': 'CONFIRMAR',
  'button.clear': 'LIMPIAR',
  'button.save': 'GUARDAR',
  'button.finish': 'FINALIZAR',
  'button.discount': 'DESCTO $5.000',
  'button.ok': 'OK',
  'button.yes': 'SI',
  'button.no': 'NO',
  'button.back': 'VOLVER',
  'button.scan': 'ESCANEAR O DIGITAR',
  'button.print': 'IMPRIMIR',
  'button.printPDF': 'IMPRIMIR',




  /* SELL MODULE */
  /***************/
  'menu.module.sell.title': 'VENDER',
  'module.sell.search.placeholder': 'CÓDIGO - TALLA',
  'module.sell.productlist.title': 'Productos',

  'module.sell.trafficlight.title': 'Gap Ventas',

  'module.sell.selectSeller.placeholder': 'VENDEDORA',
  'module.sell.selectSeller.error.message': 'Debes seleccionar una Vendedora',

  'module.sell.selectPaymentMethod.placeholder': 'MEDIO DE PAGO',
  'module.sell.selectPaymentMethod.error.message': 'Debes seleccionar un Medio de Pago',

  'module.sell.productToBuy.product.title': 'PRODUCTO',
  'module.sell.productToBuy.size.title': 'TALLA',
  'module.sell.productToBuy.price.title': 'PRECIO',

  'module.sell.customer.title': 'CLIENTE PARA ESTA COMPRA',

  'module.sell.customer.qr.button': 'QR CLIENTE',
  'module.sell.coupon.qr.button': 'QR CUPON',

  'module.sell.coupon.title': 'CUPÓN',

  'module.sell.customer.qr.error.title': 'ATENCIÓN',
  'module.sell.customer.qr.error.text': 'No se pudo obtener los datos del cliente ({error})',
  'module.sell.customer.qr.error.notfound.text': 'El cliente no se encuentra registrado',

  'module.sell.coupon.qr.error.title': 'ATENCIÓN',
  'module.sell.coupon.qr.error.text': 'No se pudo obtener los datos del cupón ({error})',
  
  'module.sell.total.title': 'TOTAL',
  
  'module.sell.productToBuy.sizeNotAvailable.title': 'ATENCIÓN',
  'module.sell.productToBuy.sizeNotAvailable.text': 'TALLA {size} NO DISPONIBLE',

  'module.sell.productToBuy.notAvailableInThisLocation.title': 'ATENCIÓN',
  'module.sell.productToBuy.notAvailableInThisLocation.text': 'PRODUCTO NO DISPONIBLE EN ESTA TIENDA',

  'module.sell.productToBuy.notfound.title': 'ATENCIÓN',
  'module.sell.productToBuy.notfound.text': 'PRODUCTO {productID} NO EXISTE',

  'module.sell.addDiscount.title': 'ATENCIÓN',
  'module.sell.addDiscount.text': 'DESCUENTO PERMITIDO SOLO PARA COMPRAS DESDE $50.000.-',

  'module.sell.addDiscount.max.title': 'ATENCIÓN',
  'module.sell.addDiscount.max.text': 'NO SE PERMITE AGREGAR MÁS DESCUENTOS',

  'module.sell.passwordmodal.title': 'CONTROL DE ACCESO',
  'module.sell.passwordmodal.password.label': 'Contraseña',

  'module.sell.passwordmodal.password.label': 'Contraseña',

  'module.sell.passwordmodal.password.error.label': 'Clave incorrecta',
  'module.sell.passwordmodal.oauth.error.label': 'Error al autenticar. Intenta nuevamente',


  'module.sell.paymentmodal.title': 'INGRESO DE BOLETA',
  'module.sell.paymentmodal.voucherCode.label': 'FOLIO BOLETA {index}',
  'module.sell.paymentmodal.voucherCode.placeholder': ' ',
  'module.sell.paymentmodal.voucherCodeAmount.label': 'MONTO BOLETA {index}',
  'module.sell.paymentmodal.voucherCodeAmount.placeholder': ' ',
  'module.sell.paymentmodal.selectPaymentMethod.label': 'MEDIO DE PAGO {index}',
  'module.sell.paymentmodal.selectPaymentMethod.placeholder': ' ',
  'module.sell.paymentmodal.localPdf.label': 'PDF BOLETA',
  'module.sell.paymentmodal.localPdf.placeholder': 'PDF BOLETA',


  'module.sell.paymentmodal.generateChangeTicket.label': 'GENERAR TICKET DE CAMBIO',

  'module.sell.paymentmodal.validation.title': 'ATENCIÓN',
  'module.sell.paymentmodal.validation.text': 'VERIFICA LOS DATOS INGRESADOS',

  'module.sell.payment.result.success.title': 'MUY BIEN',
  'module.sell.payment.result.success.text': 'VENTA GUARDADA CORRECTAMENTE (ORDEN {orderID})',

  'module.sell.payment.result.error.title': 'ATENCIÓN',
  'module.sell.payment.result.error.text': 'OCURRIÓ UN PROBLEMA AL GUARDAR LA VENTA INTENTA NUEVAMENTE (ERROR {error})',
  
  
  /* CHANGE & RETURN MODULE */
  /**************************/
  'menu.module.change-and-return.title': 'CAMBIOS Y DEVOLUCIONES',
  
  /* CHANGE MODULE */
  /*****************/
  'menu.module.change.title': 'CAMBIOS',
  'module.change.page.instructions': 'Ingresa el folio y la fecha de boleta que deseas buscar.',
  'module.change.page.order.id.label': 'ORDEN',
  'module.change.page.order.store.label': 'TIENDA',
  'module.change.page.order.seller.label': 'VENDEDORA',
  'module.change.page.order.date.label': 'FECHA',
  'module.change.page.order.size.label': 'Talla',
  'module.change.page.order.totalAmount.label': 'TOTAL',

  'module.change.page.order.newTotalAmount.label': 'NUEVO TOTAL',
  'module.change.page.order.newTotalAmountIncrement.label': 'DEBES COBRAR',
  'module.change.page.order.newTotalAmountDecrement.label': 'SALDO A FAVOR CLIENTE',
  'module.change.page.order.newTotalAmountDecrement.suggestion.label': '*SUGIERE AGREGAR OTRO PRODUCTO',

  'module.change.page.order.products.label': 'PRODUCTOS COMPRADOS',
  'module.change.page.order.newProducts.label': 'PRODUCTOS NUEVOS',

  'module.change.page.order.button.search.label': 'BUSCAR PRODUCTOS',

  'module.change.popover.message': 'Primero debes seleccionar los productos a cambiar y luego presionar el botón buscar productos',
  'module.change.popover.newTotalAmountDecrement.message': 'Sugiere agrgar un nuevo producto antes indicar monto a devolver',

  'module.change.formcontrol.missingvoucherCode.error.message': '',
  'module.change.formcontrol.voucherCodeInput.placeholder': 'BOLETA',
  'module.change.formcontrol.datePicker.label': 'FECHA',
  'module.change.formcontrol.noOrdersFound': 'NO SE ENCONTRÓ LA ORDEN CON BOLETA {voucherCode} PARA LA FECHA {orderDate}',

  'module.change.dateWithin.message': 'COMPRA DENTRO DE PLAZO ({daysExceeded} DÍAS) PARA CAMBIAR',
  'module.change.dateExceeded.message': 'COMPRA FUERA DE PLAZO ({daysExceeded} DÍAS) NO SE PUEDE DEVOLVER NI CAMBIAR PRODUCTO',

  'module.change.page.addProductsModal.title': 'BUSCAR PRODUCTOS',

  'module.change.page.addProductsModal.product.size.label': 'TALLA',
  'module.change.page.addProductsModal.product.price.label': 'PRECIO',

  'module.change.page.addProductsModal.product.sizeNotAvailable.title': 'ATENCIÓN',
  'module.change.page.addProductsModal.product.sizeNotAvailable.text': 'TALLA {size} NO DISPONIBLE',

  'module.change.page.addProductsModal.product.notAvailableInThisLocation.title': 'ATENCIÓN',
  'module.change.page.addProductsModal.product.notAvailableInThisLocation.text': 'PRODUCTO NO DISPONIBLE EN ESTA TIENDA',

  'module.change.page.addProductsModal.product.notfound.title': 'ATENCIÓN',
  'module.change.page.addProductsModal.product.notfound.text': 'PRODUCTO {productID} NO EXISTE',

  'module.change.result.success.title': 'MUY BIEN',
  'module.change.result.success.text': 'CAMBIO DE PRODUCTOS GUARDADO CORRECTAMENTE',

  'module.change.result.warning.title': 'ATENCIÓN',
  'module.change.result.warning.text': 'NO FUE POSIBLE REALIZAR EL CAMBIO DE PRODCUTO ({error}), captura esta pantalla con este mensaje e informa a tu jefatura.',

  /* RETURN MODULE */
  /*****************/
  'menu.module.return.title': 'DEVOLUCIONES',
  'module.return.page.instructions': 'Ingresa el folio y la fecha de boleta que deseas buscar.',
  'module.return.page.order.id.label': 'ORDEN',
  'module.return.page.order.store.label': 'TIENDA',
  'module.return.page.order.seller.label': 'VENDEDORA',
  'module.return.page.order.date.label': 'FECHA',
  'module.return.page.order.size.label': 'Talla',
  'module.return.page.order.totalAmount.label': 'TOTAL',

  'module.return.page.order.newTotalAmount.label': 'NUEVO TOTAL',
  'module.return.page.order.newTotalAmountIncrement.label': 'DEBES COBRAR',
  'module.return.page.order.newTotalAmountDecrement.label': 'SALDO A DEVOLVER',
  'module.return.page.order.newTotalAmountDecrement.suggestion.label': '*SUGIERE AGREGAR OTRO PRODUCTO',

  'module.return.page.order.products.label': 'PRODUCTOS COMPRADOS',
  'module.return.page.order.newProducts.label': 'PRODUCTOS NUEVOS',

  'module.return.page.order.button.return.label': 'DEVOLVER PRODUCTOS',

  'module.return.popover.message': 'Primero debes seleccionar cada producto a devolver y luego presionar el botón guardar',
  'module.return.popover.newTotalAmountDecrement.message': 'Sugiere agrgar un nuevo producto antes indicar monto a devolver',

  'module.return.formcontrol.missingvoucherCode.error.message': '',
  'module.return.formcontrol.voucherCodeInput.placeholder': 'BOLETA',
  'module.return.formcontrol.datePicker.label': 'FECHA',
  'module.return.formcontrol.noOrdersFound': 'NO SE ENCONTRÓ LA ORDEN CON BOLETA {voucherCode} PARA LA FECHA {orderDate}',

  'module.return.dateWithin.message': 'COMPRA DENTRO DE PLAZO ({daysExceeded} DÍAS) PARA CAMBIAR',
  'module.return.dateExceeded.message': 'COMPRA FUERA DE PLAZO ({daysExceeded} DÍAS) NO SE PUEDE DEVOLVER NI CAMBIAR PRODUCTO',

  'module.return.page.addProductsModal.title': 'BUSCAR PRODUCTOS',

  'module.return.page.addProductsModal.product.size.label': 'TALLA',
  'module.return.page.addProductsModal.product.price.label': 'PRECIO',

  'module.return.page.addProductsModal.product.sizeNotAvailable.title': 'ATENCIÓN',
  'module.return.page.addProductsModal.product.sizeNotAvailable.text': 'TALLA {size} NO DISPONIBLE',

  'module.return.page.addProductsModal.product.notAvailableInThisLocation.title': 'ATENCIÓN',
  'module.return.page.addProductsModal.product.notAvailableInThisLocation.text': 'PRODUCTO NO DISPONIBLE EN ESTA TIENDA',

  'module.return.page.addProductsModal.product.notfound.title': 'ATENCIÓN',
  'module.return.page.addProductsModal.product.notfound.text': 'PRODUCTO {productID} NO EXISTE',

  'module.return.result.success.title': 'MUY BIEN',
  'module.return.result.success.text': 'DEVOLUCIÓN DE PRODUCTOS GUARDADA CORRECTAMENTE',

  'module.return.result.warning.title': 'ATENCIÓN',
  'module.return.result.warning.text': 'NO FUE POSIBLE REALIZAR LA DEVOLUCIÓN DE PRODUCTOS ({error}), captura esta pantalla con este mensaje e informa a tu jefatura.',

  
  /* ECOMMERCE MODULE */
  /********************/
  'menu.module.ecommerce.title': 'ECOMMERCE',
  
  
  /* ECOMMERCE - DELIVERY MODULE */
  /*******************************/
  'menu.module.ecommerce.delivery.title': 'ENVÍOS',
  'menu.module.ecommerce.delivery.orderdetail.title': 'ENVÍOS - DETALLE ORDEN {orderID}',

  'module.ecommerce.delivery.noSales.message': 'NO HAY ORDENES PENDIENTES PARA HOY',
  'module.ecommerce.delivery.orderID.label': 'Orden',
  'module.ecommerce.delivery.date.label': 'Fecha',
  'module.ecommerce.delivery.amount.label': 'Monto',
  'module.ecommerce.delivery.address.label': 'Dirección',
  'module.ecommerce.delivery.orderdetailbutton.label': 'VER',
  'module.ecommerce.delivery.taskstodo.label': 'TAREAS A REALIZAR',
  'module.ecommerce.delivery.productstosend.label': 'PRODUCTOS A ENVIAR',
  'module.ecommerce.delivery.printShippingLabelButton.label': 'Generar Etiqueta',

  'module.ecommerce.delivery.productstate.label': 'Revisar Estado de Productos',
  'module.ecommerce.delivery.prepareproducttosend.label': 'Empaquetar Productos para Envío',
  'module.ecommerce.delivery.printshippinglabel.label': 'Imprimir Etiqueta de Envío',
  'module.ecommerce.delivery.setproductvoucher.label': 'Ingresar Folio de Boleta',
  'module.ecommerce.delivery.deliverproduct.label': 'Enviar Productos por Curier',
  'module.ecommerce.delivery.shippingCompany.label': 'Seleccionar Empresa de Envío',
  'module.ecommerce.delivery.shippingCompany.select.label': 'Selecciona',
  'module.ecommerce.delivery.setproducttrackingnumber.label': 'Ingresar Código de Seguimiento',

  'module.ecommerce.delivery.success.title': 'MUY BIEN',
  'module.ecommerce.delivery.success.text': 'ENVÍO GUARDADO CORRECTAMENTE',
  
  'module.ecommerce.delivery.validateCheckboxesAndInputsError.title': 'ATENCIÓN',
  'module.ecommerce.delivery.validateCheckboxesAndInputsError.text': 'ASEGÚRATE DE MARCAR TODAS LAS TAREAS Y COMPLETAR FOLIO Y SEGUIMIENTO',
  

  /* ECOMMERCE - CLICK & COLLECT MODULE */
  /**************************************/
  'menu.module.ecommerce.clickandcollect.title': 'RETIRO EN TIENDA',
  'menu.module.ecommerce.clickandcollect.orderdetail.title': 'RETIRO EN TIENDA - DETALLE ORDEN {orderID}',

  'module.ecommerce.clickandcollect.noSales.message': 'NO HAY ORDENES PENDIENTES PARA HOY',
  'module.ecommerce.clickandcollect.orderID.label': 'Orden',
  'module.ecommerce.clickandcollect.date.label': 'Fecha',
  'module.ecommerce.clickandcollect.amount.label': 'Monto',
  'module.ecommerce.clickandcollect.process.label': 'Proceso',
  'module.ecommerce.clickandcollect.orderdetailbutton.label': 'VER',

  'module.ecommerce.clickandcollect.taskstodo.label': 'TAREAS A REALIZAR',
  'module.ecommerce.clickandcollect.purchasedproducts.label': 'PRODUCTOS COMPRADOS',
  'module.ecommerce.clickandcollect.printClickAndCollectLabelButton.label': 'Generar Etiqueta de Retiro',

  'module.ecommerce.clickandcollect.code.label': 'Código',
  'module.ecommerce.clickandcollect.size.label': 'Talla',

  'module.ecommerce.clickandcollect.productstate.label': 'Revisar Estado de Productos',
  'module.ecommerce.clickandcollect.prepareproducttosend.label': 'Empaquetar Productos',
  'module.ecommerce.clickandcollect.printClickAndCollectlabel.label': 'Imprimir Etiqueta de Retiro',
  'module.ecommerce.clickandcollect.setproductvoucher.label': 'Ingresar Folio de Boleta',
  'module.ecommerce.clickandcollect.deliverproduct.label': 'Enviar Productos por Curier',
  'module.ecommerce.clickandcollect.setproducttrackingnumber.label': 'Ingresar Código de Seguimiento',

  'module.ecommerce.clickandcollect.modal.title.label': 'CONFIRMAR RETIRO ORDEN {orderID}',
  'module.ecommerce.clickandcollect.modal.customer.label': 'PERSONA QUE RETIRA',
  'module.ecommerce.clickandcollect.modal.instructions': 'Solicita al cliente que te muestre el email de notificación y confirma los datos con la información para retiro.',


  'module.ecommerce.clickandcollect.success.title': 'MUY BIEN',
  'module.ecommerce.clickandcollect.success.text': 'RETIRO LISTO PARA RECOGER POR CLIENTE',
  'module.ecommerce.clickandcollect.pickupproduct.success.text': 'PRODUCTO ENTREGADO AL CLIENTE',

  'module.ecommerce.clickandcollect.result.error.title': 'ATENCIÓN',
  'module.ecommerce.clickandcollect.result.error.text': 'OCURRIÓ UN PROBLEMA AL CAMBIAR ESTAO DE RETIRO EN TIENDA (ERROR {error})',
  
  'module.ecommerce.clickandcollect.validateCheckboxesAndInputsError.title': 'ATENCIÓN',
  'module.ecommerce.clickandcollect.validateCheckboxesAndInputsError.text': 'ASEGÚRATE DE MARCAR TODAS LAS TAREAS Y COMPLETAR FOLIO',

  /* GOODS MODULE */
  /****************/
  'menu.module.goods.title': 'MERCADERÍA',
  'menu.module.goods.movements.title': 'INGRESO - EGRESO',

  
  /* REPORT MODULE */
  /*****************/
  'menu.module.report.title': 'REPORTES',
  'menu.module.report.salesofday.title': 'VENTAS DEL DÍA',
  'menu.module.report.binnacle.title': 'BITÁCORA',
  'menu.module.report.stockinstore.title': 'CONSULTA DE STOCK',
  
  'module.report.stockinstore.page.title': 'CONSULTA DE STOCK',
  'module.report.stockinstore.page.description': 'Este módulo te permite generar un informe de inventario en tienda.',
  'module.report.stockinstore.page.instructions': 'Ingresa o escanea el código del producto que deseas buscar y obtendrás detalles sobre su disponibilidad en las diferentes ubicaciones de tienda.',

  'module.report.stockinstore.formcontrol.placeholder': 'CÓDIGO',
  'module.report.stockinstore.formcontrol.missingproductid.error.message': 'Debes ingresar un Código de Producto',
  'module.report.stockinstore.formcontrol.aria-label': 'Código de Producto',
  'module.report.stockinstore.formcontrol.search-button.text': 'BUSCAR',
  'module.report.stockinstore.formcontrol.clear-button.text': 'LIMPIAR RESULTADOS',

  'module.report.stockinstore.product.title': 'PRODUCTO',
  'module.report.stockinstore.product.size': 'TALLA',
  'module.report.stockinstore.product.location': 'UBICACIÓN',

  "module.report.stockinstore.error.message": "Ocurrió un error al buscar el producto. Por favor, intenta nuevamente más tarde.",
  "module.report.stockinstore.product.notavailable.message": "Producto no disponible en ninguna talla",
  
  'module.report.salesofday.sales.label': 'VENTAS',
  'module.report.salesofday.grosstotal.label': 'TOTAL BRUTO',
  'module.report.salesofday.nettotal.label': 'TOTAL NETO',
  'module.report.salesofday.orderID.label': 'Orden',
  'module.report.salesofday.date.label': 'Fecha',
  'module.report.salesofday.amount.label': 'Monto',
  'module.report.salesofday.paymentmethod.label': 'Forma de Pago',
  'module.report.salesofday.orderdetailbutton.label': 'VER',
  'module.report.salesofday.noSales.message': 'AÚN NO HAY VENTAS PARA HOY',
  'module.report.salesofday.exchangeticketbutton.label': 'Ver Ticket',
  'module.report.salesofday.printticketbutton.label': 'Imprimir Ticket de Cambio',

  'module.report.salesofdayorderdetail.order.label': 'Orden',
  'module.report.salesofdayorderdetail.date.label': 'Fecha',
  'module.report.salesofdayorderdetail.seller.label': 'Vendedora',
  'module.report.salesofdayorderdetail.total.label': 'TOTAL',
  'module.report.salesofdayorderdetail.paymentmethod.label': 'Forma de Pago',
  'module.report.salesofdayorderdetail.receipt.label': 'Boleta',
  'module.report.salesofdayorderdetail.product.label': 'Producto',
  'module.report.salesofdayorderdetail.code.label': 'Código',
  'module.report.salesofdayorderdetail.size.label': 'Talla',

  'module.report.binnacle.seller.placeholder': 'SELECCIONA',
  'module.report.binnacle.chatInput.placeholder': 'Mensaje',
  'module.report.binnacle.seller.missingSeller.message': 'Debes seleccionar un nombre',


  /* SETTINGS MODULE */
  /*******************/
  'menu.module.settings.title': ' ',
  'settings.page.title': 'Configuración',
  'settings.enter_password': 'Cambuar Ubicación',
  'settings.incorrect_password': 'Clave incorrecta',


};
export default enMessages;
