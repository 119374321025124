import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Col, Row, Alert} from 'react-bootstrap';
import Select from 'react-select';
import { paymentMethodForSelect } from 'constants/defaultValues';
import { injectIntl } from 'react-intl';
import IntlMessages from 'helpers/IntlMessages';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Swal from 'sweetalert2';
import CurrencyFormat from 'react-currency-format';

const PaymentModalForm = ({ showModal, setShowModal, paymentMethod, totalAmount, handlePaymentData, generateChangeTicket, setGenerateChangeTicket, intl }) => {
  const [vouchers, setVouchers] = useState([]);
  const [voucherTotal, setVoucherTotal] = useState();

  useEffect(() => {
    if (paymentMethod && paymentMethod.value !== "MIXTO") {
      setVouchers([
        { voucherCode: "", voucherCodeAmount: totalAmount, paymentMethod: paymentMethod }
      ]);
    }
  }, [totalAmount, paymentMethod]);

  useEffect(() => {
    if (paymentMethod && paymentMethod.value === "MIXTO") {
      setVouchers([
        { voucherCode: "", voucherCodeAmount: "", paymentMethod: "" },
        { voucherCode: "", voucherCodeAmount: "", paymentMethod: "" },
        { voucherCode: "", voucherCodeAmount: "", paymentMethod: "" }
      ]);
    }
  }, [paymentMethod]);

  useEffect(() => {
    const newTotal = vouchers.reduce((acc, currVoucher) => acc + (currVoucher.voucherCodeAmount || 0), 0);
    setVoucherTotal(newTotal);
  }, [vouchers]);

  const validateForm = () => {
    let validVoucherCount = 0;
    for (let voucher of vouchers) {
      if (voucher.voucherCode && voucher.voucherCodeAmount && voucher.paymentMethod) {
        validVoucherCount += 1;
      }
    }
    
    if (paymentMethod.value === "MIXTO" && validVoucherCount < 2) {
      return false;
    } else if (validVoucherCount === 0) {
      return false;
    }

    // check if total equals totalAmount
    if (voucherTotal !== totalAmount) {
      return false;
    }
    
    return true;
};

  const onSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      setShowModal(false);
      handlePaymentData(vouchers);
    } else {
      Swal.fire({
        title: intl.formatMessage({id: 'module.sell.paymentmodal.validation.title'}),
        text: intl.formatMessage({id: 'module.sell.paymentmodal.validation.text'}),
        icon: "warning",
        showCancelButton: false,
        confirmButtonText: intl.formatMessage({id: 'button.accept'})
      });
    }
  };

  const handleInputChange = (index, field, value) => {
    setVouchers(prevVouchers =>
      prevVouchers.map((voucher, idx) =>
        idx === index ? { ...voucher, [field]: value } : voucher
      )
    );
  };

  return (
    <Modal backdrop="static" keyboard={false} show={showModal} onHide={() => setShowModal(false)}>
        <Form onSubmit={onSubmit}>
            <Modal.Header>
                <Modal.Title><IntlMessages id="module.sell.paymentmodal.title" /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {vouchers.map((voucher, index) => (
                  <Row key={index}>
                      <Form.Group as={Col}>
                      <Form.Label>{intl.formatMessage({id: 'module.sell.paymentmodal.voucherCode.label'}, {index: index + 1})}</Form.Label>
                      <Form.Control
                          type="text"
                          placeholder={intl.formatMessage({id: 'module.sell.paymentmodal.voucherCode.placeholder'})}
                          name={`voucherCode${index + 1}`}
                          value={voucher.voucherCode}
                          onChange={(e) => handleInputChange(index, 'voucherCode', e.target.value)}
                      />
                      </Form.Group>
                      <Form.Group as={Col}>
                      <Form.Label>{intl.formatMessage({id: 'module.sell.paymentmodal.voucherCodeAmount.label'}, {index: index + 1})}</Form.Label>
                      <Form.Control
                          type="number"
                          placeholder={intl.formatMessage({id: 'module.sell.paymentmodal.voucherCodeAmount.placeholder'})}
                          name={`voucherCodeAmount${index + 1}`}
                          value={(paymentMethod.value === "MIXTO") ? voucher.voucherCodeAmount : totalAmount}
                          onChange={(e) => handleInputChange(index, 'voucherCodeAmount', parseFloat(e.target.value))}
                      />
                      </Form.Group>
                      {paymentMethod.value === "MIXTO" && (
                      <Form.Group as={Col}>
                          <Form.Label>{intl.formatMessage({id: 'module.sell.paymentmodal.selectPaymentMethod.label'}, {index: index + 1})}</Form.Label>
                          <Select
                          name={`paymentMethod${index + 1}`}
                          options={paymentMethodForSelect}
                          placeholder={intl.formatMessage({id: 'module.sell.paymentmodal.selectPaymentMethod.placeholder'})}
                          isClearable={false}
                          isSearchable={false}
                          onChange={(selectedOption) => handleInputChange(index, 'paymentMethod', selectedOption)}
                          />
                      </Form.Group>
                      )}
                  </Row>
                ))}
                <Row>
                  <Col></Col>
                  <Col>
                    <div className="d-flex justify-content-center mt-3">
                      <h4>
                        <p>TOTAL</p>
                        <p><CurrencyFormat value={(voucherTotal != null) ? voucherTotal : 0} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} prefix={'$ '} /></p>
                      </h4>
                    </div>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <div className="text-center">
                    <Alert variant="info" className="text-center">
                      <Form.Group>
                        <Form.Check
                          type="switch"
                          id="generateChangeTicket"
                          label={intl.formatMessage({id: 'module.sell.paymentmodal.generateChangeTicket.label'})}
                          checked={generateChangeTicket}
                          onChange={(e) => setGenerateChangeTicket(e.target.checked)}
                        />
                      </Form.Group>                      
                    </Alert>
                  </div>                
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn-icon btn-icon-end" variant="danger" size="xl" onClick={() => setShowModal(false)}>
                  <span><IntlMessages id="button.cancel" /></span> <CsLineIcons icon="close-circle" />
                </Button>
                <Button className="btn-icon btn-icon-end" variant="primary" size="xl" type="submit">
                  <span><IntlMessages id="button.save" /></span> <CsLineIcons icon="save" />
                </Button>                
            </Modal.Footer>      
        </Form>
    </Modal>
  );
};

export default injectIntl(PaymentModalForm);
