import moment from 'moment';

export const orderVO = {
    id: null,
    addresses: null,
    autorizationID: null,
    channel: '',
    createDate: '',
    customerEmail: '',
    customerName: '',
    date: moment().format('YYYY-MM-DD'),
    location: '',
    notes: null,
    orderType: '',
    paymentGateway: '',
    paymentMethod: '',
    previousTotalAmount: null,
    products: [],
    productsPreChange: null,
    sellerEmail: '',
    sellerName: '',
    shippingValue: null,
    shippingCompany: null,
    status: '',
    totalAmount: 0,
    trackingNumber: null,
    transactionID: 0,
    updateDate: null,
    voucherCode: null,
    voucherCode2: null,
    voucherCode2Amount: null,
    voucherCode2PaymentMethod: null,
    voucherCode3: null,
    voucherCode3Amount: null,
    voucherCode3PaymentMethod: null,
    voucherCodeAmount: null,
    voucherCodePaymentMethod: null
}

export const productVO = {
    brand: null,
    categories: '',
    color: 'white',
    createDate: '',
    description: '',
    descriptionShort: '',
    enabled: false,
    id: '',
    imageGallery: [],
    items: [
        {
            id: 1,
            attributes: [
                {
                    field: 'Stock Quantity',
                    type: 'stockQuantity',
                    value: 0
                },
                {
                    field: 'Size Number',
                    type: 'sizeNumber',
                    value: 'Unica'
                }
            ],
            dateBuy: '2022-01-01',
            importCosts: null,
            importCostsCurrency: null,
            location: 'warehouse#bodega.alsacia#depto.303',
            referenceExternalID: null,
            referenceOrderNumber: null,
            referencePrice: 1,
            referencePriceCurrency: 'CLP',
            stockStatus: 'out_of_stock'
        }
    ],
    manufactureCountry: '',
    manufacturer: '',
    metaDescription: null,
    metaKeyword: null,
    metaTitle: null,
    name: '',
    newsFromDate: null,
    newsToDate: null,
    price: [
        {
            currency: 'CLP',
            type: 'normal',
            value: ''
        },
        {
            currency: 'CLP',
            type: 'internet',
            value: ''
        },
        {
            currency: 'CLP',
            type: 'special',
            value: ''
        }
    ],
    referenceUrl: '',
    shipmentType: null,
    sku: '',
    skuType: null,
    specialFromDate: null,
    specialToDate: null,
    title: '',
    type: '',
    updateDate: '',
    videoGallery: [],
    sellerMarketplace: ''
}
