import React, { useState, createContext } from 'react';

// Crear el contexto
const SSEContext = createContext(0);

// Proveedor del contexto que encapsula el estado de badge
const SSEProvider = ({ children }) => {
    const [badge, setBadge] = useState(0);

    return (
        <SSEContext.Provider value={{ badge, setBadge }}>
            {children}
        </SSEContext.Provider>
    );
};

export { SSEContext, SSEProvider }